import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from './Header/Header'
import Footer from './Footer/Footer'
import '../styles/style.css'
import Particles from 'react-particles-js';

const particlesOptions = {
                "particles": {
    "number": {
      "value": 100,
      "density": {
        "enable": true,
        "value_area": 946.9771699587272
      }
    },
    "color": {
      "value": "#003a52"
    },
    "shape": {
      "type": "circle",
      "stroke": {
        "width": 0,
        "color": "#000000"
      },
      "polygon": {
        "nb_sides": 5
      },
      "image": {
        "src": "img/github.svg",
        "width": 100,
        "height": 100
      }
    },
    "opacity": {
      "value": 0.4419226793140727,
      "random": false,
      "anim": {
        "enable": false,
        "speed": 1,
        "opacity_min": 0.1,
        "sync": false
      }
    },
    "size": {
      "value": 4,
      "random": true,
      "anim": {
        "enable": false,
        "speed": 40,
        "size_min": 0.1,
        "sync": false
      }
    },
    "line_linked": {
      "enable": true,
      "distance": 50,
      "color": "#005f6b",
      "opacity": 0.2367442924896818,
      "width": 1
    },
    "move": {
      "enable": true,
      "speed": 3.2,
      "direction": "none",
      "random": true,
      "straight": false,
      "out_mode": "out",
      "bounce": false,
      "attract": {
        "enable": false,
        "rotateX": 7295.524876938129,
        "rotateY": 1200
      }
    }
  },
  "interactivity": {
    "detect_on": "window",
    "events": {
      "onhover": {
        "enable": true,
        "mode": "grab"
      },
      "onclick": {
        "enable": true,
        "mode": "repulse"
      },
      "resize": true
    },
    "modes": {
      "grab": {
        "distance": 158.35505639876231,
        "line_linked": {
          "opacity": 0.48534504742396906
        }
      },
      "bubble": {
        "distance": 400,
        "size": 40,
        "duration": 2,
        "opacity": 8,
        "speed": 3
      },
      "repulse": {
        "distance": 235.50239156739008,
        "duration": 0.4
      },
      "push": {
        "particles_nb": 4
      },
      "remove": {
        "particles_nb": 2
      }
    }
  },
  "retina_detect": true
             }

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div className="flex flex-col main-util">
        <Header siteTitle={data.site.siteMetadata.title} />
       <Particles className="particles" params={particlesOptions} />
       <div className="m-10 mt-2">
          {children}
        </div>

        <Footer className="footer-util"/>
      </div>
    )}
  />
  
)


Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
