import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import './Header.css'


const Header = () => {
return(
	<div className="header">
		<nav className="cl-effect-8">
			<Link to="/">Home</Link>
			<Link to="/skills">Skills</Link>
			<Link to="/projects">Projects</Link>
		
		</nav>
	</div>
	);
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header